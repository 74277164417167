.timelineClasses {
	:global(.ant-timeline-item-last) {
		padding-bottom: 0 !important;
	}
}
.stickyDot {
	&:global(.ant-timeline) {
		:global(.ant-timeline-item-head-custom) {
			position: sticky;
			top: 15px;
			padding-top: 10px;
			transform: translate(-49.5%, -50%);
			background: transparent;
		}
	}
	&:global(.ant-timeline) {
		:global(.ant-timeline-item) {
			padding-bottom: 0;
		}
		:global(.ant-timeline-item-content) {
			top: -40px;
		}
	}
}
