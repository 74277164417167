.Right {
	:global {
		.ant-segmented-item {
			border-radius: 0 1rem 1rem 0; // rounded corners
		}
		.ant-segmented-thumb {
			border-radius: 0 1rem 1rem 0; // rounded corners
		}
		.ant-segmented-item.ant-segmented-item-selected {
			margin: -1px -1px 0 0;
		}
	}
}
.Left {
	:global {
		.ant-segmented-item {
			border-radius: 1rem 0 0 1rem; // rounded corners
		}
		.ant-segmented-thumb {
			border-radius: 1rem 0 0 1rem; // rounded corners
		}
		.ant-segmented-item.ant-segmented-item-selected {
			margin: -1px 0 0 -1px;
		}
	}
}
.segmentedButton {
	// Base styles for the Segmented component
	background-color: #f5f5f5; // light grey background
	border-radius: 1rem; // rounded corners
	height: 2.5rem;
	// width: 20rem;
	padding: 0;
	border: 1px solid #d3d8df;
	// Ensure styles apply to all nested .ant-segmented-item elements
	:global {
		.ant-segmented-item {
			color: #333 !important; // text color for each segment
			font-weight: 500 !important; // medium font weight
			// background-color: transparent;
			width: 10rem;
			.ant-segmented-item-label {
				// color: black !important;
				// border-radius: 1rem; // rounded corners
				height: 2.5rem;
				@apply tw-flex tw-items-center tw-justify-center;

				&:hover {
					background-color: transparent !important;
					// color: black !important;
					opacity: 1;
				}
			}
			&::after {
				content: none;
			}
		}
		.ant-segmented-thumb {
			@apply tw-bg-color-orange-80;
			@apply tw-bg-color-orange-80 tw-text-color-text-primary tw-border tw-border-solid tw-border-color-orange-200;
			height: 2.5rem;
		}

		.ant-segmented-item.ant-segmented-item-selected {
			@apply tw-bg-color-orange-80 tw-text-color-text-primary tw-border tw-border-solid tw-border-color-orange-200;
			color: #ec5d25 !important;
			// box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important; // subtle shadow
			// border-radius: 1rem;
			height: 2.5rem;
			.ant-segmented-item-label {
				// border-radius: 1rem; // rounded corners
				padding: 0 12px !important; // padding around label text
				font-size: 14px !important; // label font size
			}
		}
	}
}
