.card {
	@apply tw-h-fit tw-w-max tw-p-4 tw-rounded-lg tw-border tw-border-solid tw-border-gray-100;
	// box-shadow: 0px 4px 8px 0px #11182726;

box-shadow: 0px 0px 2px 0px #1118271A;

	&:hover {
		// box-shadow: 0px 2px 3px 1px #1118271A;
	}
}

