.collapse {
	&:global(.ant-collapse) {
		:global(.ant-collapse-header-text) {
			@apply tw-typography-title3 tw-text-color-text-default;
		}
		:global(.ant-collapse-header) {
			@apply tw-px-0;
		}
		:global(.ant-collapse-content-box) {
			@apply tw-px-0;
		}
	}
}
