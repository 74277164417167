@import '../../../theming/_init';

.button {
	&.disabled {
		background-color: #ec5d25;
		color: white;
		border-color: transparent;

		&.default {
			background-color: white;
			color: black;
			border: 1px solid #c1c3c7;
			box-shadow: 0px 1px 0px rgba(17, 24, 39, 0.1);
		}
		&.secondary {
			background-color: white;
			color: black;
			border: 1px solid #d3d8df;
			box-shadow: 0px 1px 0px rgba(17, 24, 39, 0.1);
		}
		&.link {
			border: none;
			box-shadow: none;
			background-color: white;
			@apply tw-text-color-text-primary tw-border-0 tw-p-0;
		}
		&.text {
			background: transparent;
			&:hover {
				@apply tw-bg-transparent tw-text-color-text-disabled;
			}
		}
		&.contain {
			@apply tw-border-solid tw-border tw-border-color-defaultSubtle;
			@apply tw-text-color-text-primary;
			background-color: white;
		}
	}
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	height: 40px;
	box-shadow: $shadow100;
	border-radius: 8px;
	&.secondary {
		border: 1px solid #d3d8df;
		box-shadow: 0px 1px 0px rgba(17, 24, 39, 0.1);
	}
	&.text {
		&.disabled {
			@apply tw-text-color-text-disabled tw-opacity-100;
		}
	}
	&.contain {
		@apply tw-border-solid tw-border tw-border-color-defaultSubtle;
		@apply tw-text-color-text-primary;
	}
	&.link {
		border: none;
		box-shadow: none;
		@apply tw-text-color-text-primary tw-border-0 tw-p-0;
		&:global(.ant-btn-link) {
			&:hover {
				@apply tw-text-color-text-primary;
			}
		}
	}
	&.vanilla,
	&.black {
		border: none;
		outline: none;
		background-color: white;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus {
			box-shadow: none;
			border: none;
			outline: none;
		}
	}
	&.black {
		background-color: black;
		color: white;
	}
	&:global(.ant-btn-default) {
		font-weight: $semibold;
	}
	&:global(.ant-btn-text) {
		@apply tw-text-color-text-default tw-border-0 tw-p-0;
		box-shadow: none;
		&:hover {
			@apply tw-text-color-text-default tw-bg-transparent;
		}
	}
}
